import React from 'react';
import { Layout, SEO } from 'components/common';
import { Praxis } from 'components/landing/Praxis';

export default () => (
  <Layout>
    <SEO />
    <Praxis/>
  </Layout>
);
