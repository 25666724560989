import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import signo from 'assets/images/dr-kereszty.png';
import { Wrapper, SkillsWrapper, Details, Thumbnail, Signo, SignoTitle, Diploma } from './styles';
import { FormattedMessage } from "gatsby-plugin-intl"
import { Header } from 'components/theme';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Praxis = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <StaticQuery
      query={graphql`
        query PraxisQuery {
          file(relativePath: { eq: "intro_bg.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          } 
        }
      `}
render={data => (
    <Wrapper>
      <Header />
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt="dr. Kereszty Praxis, Team"
                />
        </Thumbnail>
        <Details theme={theme}>
          <div>
            <p><FormattedMessage id="praxis.text.1" /></p>
            <p><FormattedMessage id="praxis.text.2" /></p>
            <p><FormattedMessage id="praxis.text.3" /></p>
            <p><FormattedMessage id="praxis.text.4" /></p>
            <p><FormattedMessage id="praxis.text.5" /></p>
            <p><FormattedMessage id="praxis.text.6" /></p>
            <Signo><img src={signo} alt="dr. Kereszty Praxis" /></Signo>
            <SignoTitle>Dr Kereszty Kornél MSc</SignoTitle>
            <Diploma>
              <p><FormattedMessage id="praxis.text.7" /></p>
              <p><a href="http://www.med-college.de/de/lehre/studiengaenge/implantology/international-course/faculty_staff.php" target="blank"><FormattedMessage id="praxis.text.8" /></a></p>
            </Diploma>
          </div>
          
        </Details>
      </SkillsWrapper>
    </Wrapper>
      )}
      />
    )
  };
